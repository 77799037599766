require('./bootstrap');

$(document).ready(function() {
    // Show the button when scrolled down 20px from the top
    $(window).scroll(function() {
        if ($(this).scrollTop() > 0) {
            $('#header').addClass('sticky-header');
        } else {
            $('#header').removeClass('sticky-header');
        }
        if ($(this).scrollTop() > 200) {
            $('#gotoTop').fadeIn();
        } else {
            $('#gotoTop').fadeOut();
        }
    });

    // Scroll to the top of the page when the button is clicked
    $('#gotoTop').click(function() {
        $('html, body').animate({ scrollTop: 0 }, 'smooth');
        return false;
    });
});

$(document).on("click", ".js-profile-icon", function (e) {
    let profileTopMenu = $('.js-dropdown-menu');
    if (profileTopMenu.is(":visible")) {
        profileTopMenu.hide();
    } else {
        profileTopMenu.show();
    }
});

$(document).on("click", ".js-bars-menu", function () {
    let primaryTopMenu = $('.js-primary-menu');
    if (primaryTopMenu.is(':visible')) {
        primaryTopMenu.hide();
    } else {
        primaryTopMenu.show();
    }
});

$(document).on("click", ".js-page-menu-trigger", function () {
    let pageTopMenu = $('.page-menu-nav');
    if (pageTopMenu.hasClass('d-none')) {
        pageTopMenu.removeClass('d-none');
    } else {
        pageTopMenu.addClass('d-none');
    }
});


$(document).on("click", ".js-modal-login", function (e) {
    e.preventDefault();
    let href = $('#login-form').attr('action');
    let formData = new FormData();
    formData.append('email', $('#login-form #email').val());
    formData.append('password', $('#login-form .js-input-password').val());
    formData.append('remember', $('#login-form #inlineCheckbox2').val());
    formData.append('search_string', $('#login-form .js-search-string').val());
    formData.append('institution', $('#login-form .js-file-institution').val());
    formData.append('part_name', $('#login-form .js-file-part-name').val());

    submitForm(href, false, formData, 'user-login');
});

$(document).on("click", ".js-submit-file-notes", function (e){
    e.preventDefault();
    let href = $('.js-file-notes-form').attr('action');
    let formData = new FormData();
    formData.append('file_id', $('.js-file-notes-form .js-file-id').val());
    formData.append('note', $('.js-file-notes-form .js-file-notes').val());
    formData.append('file_number', $('.js-file-notes-form .js-file-number').val());
    formData.append('institution', $('.js-file-notes-form .js-file-institution').val());
    submitForm(href, false, formData, 'file-notes');
});

$(document).on("click", ".js-with-confirm", function(e) {
    e.preventDefault();

    let href = $(this).data('href');
    let title = $(this).text();
    let message = $(this).data('confirm-message');
    let btnOk = $(this).data('confirm-ok');
    let btnOkClass = $(this).data('btn-ok-class');
    let btnCancel = $(this).data('confirm-cancel');
    let withReload = $(this).data('location-reload');
    let formAction = $(this).data('action');
    let fileNumber = $(this).data('file-number');
    let institution = $(this).data('institution');
    let partName = $(this).data('part-name');

    let formData = new FormData();
    if (formAction === 'update-file-status' || formAction === 'delete-file') {
        formData.append('id', $(this).data('file-id'));
    }
    if (formAction === 'login') {
        //sessionStorage.setItem("trackFileNumber", JSON.stringify(fileNumber));
    }

    doConfirm({
        title:title,
        message: message,
        btnOk: btnOk,
        btnOkClass: btnOkClass,
        btnCancel: btnCancel
    }, () =>{
        if (formAction === 'login') {
            $('#login-form').find('.js-error-msg').text('');
            $('#login-form').find('.js-search-string').val(fileNumber);
            $('#login-form').find('.js-file-institution').val(institution);
            $('#login-form').find('.js-file-part-name').val(partName);
            if (fileNumber.length > 0) {
                let fbHref = $('.js-login-facebook').attr('href');
                let googleHref = $('.js-login-google').attr('href');
                fbHref +=  '/' + fileNumber.replaceAll('/', '_');
                googleHref +=  '/' + fileNumber.replaceAll('/', '_');
                if (institution.length > 0) {
                    fbHref += '/' + institution.replaceAll(' ', '_');
                    googleHref += '/' + institution.replaceAll(' ', '_');
                }
                if (partName.length > 0) {
                    fbHref += '/' + partName.replaceAll(' ', '_');
                    googleHref += '/' + partName.replaceAll(' ', '_');
                }
                $('.js-login-facebook').attr('href', fbHref);
                $('.js-login-google').attr('href', googleHref);
            }

            $('#modal-show-login').modal('show');
        } else {
            submitForm(href,withReload, formData, formAction);
        }
    })
});

$(document).on("click", ".js-close-modal", function (e) {
   e.preventDefault();
   let modalId =  $(this).parents('.modal').attr('id');
   $('#' + modalId).modal('hide');
});

$(document).on("click", '.js-show-password', function () {
    let inputPassword = $(this).closest('div').find('.js-input-password');
    if (inputPassword.attr('type') === 'password') {
        inputPassword.attr('type', 'text');
        $(this).addClass('passowd-shown');
    } else {
        inputPassword.attr('type', 'password');
        $(this).removeClass('passowd-shown');
    }
});

$(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
});

$(document).ready(function () {
    $('.js-search-file-by').on('click', function () {
        $('.js-institution').val(null).trigger('change');
        $('.js-search-string').val('');
        $('.js-err-institution').text('');
        $('.js-err-search_input').text('');

        let searchBy = $(this).val();
        if (searchBy === 'part-name') {
            $('.js-search-file-label').text('Nume și Prenume');
            $('.js-search-info-by').text('Cată după parte din dosar:');
            $('.js-search-info-txt').text('alege instanța și numele părții');
            $('.js-show-instance').removeClass('d-none');
            $('.js-search-string').attr('placeholder', 'Nume parte din dosar');

            if ($('.js-institution').children('option').length <= 1) {
                $(function () {
                    $.getJSON(window.institutionsUrl, function(response) {
                        $('.js-institution').select2({
                            multiple: false,
                            theme: "bootstrap4",
                            data: response.institutions
                        });
                    });
                });
            }
        }
        if (searchBy === 'file-number') {
            $('.js-search-file-label').text('Dosar');
            $('.js-search-info-by').text('Caută dosar după număr:');
            $('.js-search-info-txt').text('număr/instanța/an (xxxx/xx/xxxx)');
            $('.js-show-instance').addClass('d-none');
            $('.js-search-string').attr('placeholder', 'Număr dosar');
        }
    });

    let flashMessage = $('.js-flash-message');
    if (flashMessage.length > 0){
        flashMessage.delay(12000).fadeOut(300);
    }

    //logout
    const logoutBtn = $(".js-user-logout");
    const logoutForm = $("#logout-form");
    logoutBtn.on("click", function (e) {
        e.preventDefault();
        logoutForm.submit();
    });

    let consentStr = localStorage.getItem('cookieSeen');
    if(!consentStr) {
        $("#cookieConsent").css('visibility','visible').hide().fadeIn(200);
    } else {
        let consent = JSON.parse(consentStr);
        const now = new Date();

        if (now.getTime() > consent.expiry) {
            localStorage.removeItem('cookieSeen');
            return null;
        }
    }

    $('#js-close-cookie-consent').on('click', function () {
        setCookieSeen(false);
    });

    $('.js-cookie-consent-ok').on('click', function () {
        setCookieSeen(true);
    });
});

function setCookieSeen(accepted) {
    let expired = 10000;
    if (accepted === true) {
        expired = 999999999;
    }

    const now = new Date();
    const item = {
        value: true,
        expiry: now.getTime() + expired,
    };
    localStorage.setItem('cookieSeen', JSON.stringify(item));
    $("#cookieConsent").fadeOut(200);
}
